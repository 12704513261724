<template>
  <div class="contractList">
    <!-- <Loading v-show="$store.state.isLoading"/> -->
    <van-tabs v-model="active" type="card" offset-top='5px' @change='tabChange'>
      <van-tab title="按合同查看">
        <Loading class="iframeBox" v-if='isLoading1'/>
        <iframe  class="iframeBox" id='pdf1Iframe' height="100%" :key='pdf1_name' :src="'./electronicRent/pdfDisplay?pdfName=' + pdf1_name"></iframe>
        <div class='footer_button'>
          <van-button round type="primary" size="small" style="padding: 0px 26px;margin-right: 20px;" @click="toShare1('Excel')">
            <img class='button_share' src='@/assets/img/excel_share.png'>
            <span class='button_text'>分享Excel</span>
          </van-button>
          <van-button round type="danger" size="small" style="padding: 0px 26px;" @click="toShare1('PDF')">
            <img class='button_share' src='@/assets/img/excel_share.png'>
            <span class='button_text'>分享PDF</span>
          </van-button>
        </div>
      </van-tab>
      <van-tab title="按单车查看">
        <Loading class="iframeBox" v-if='isLoading2'/>
        <iframe class="iframeBox" height="100%" :key='pdf2_name' :src="'./electronicRent/pdfDisplay?pdfName=' + pdf2_name"></iframe>
        <div class='footer_button'>
          <van-button round type="primary" size="small" style="padding: 0px 26px;margin-right: 20px;" @click="toShare2('Excel')">
            <img class='button_share' src='@/assets/img/excel_share.png'>
            <span class='button_text'>分享Excel</span>
          </van-button>
          <van-button round type="danger" size="small" style="padding: 0px 26px;" @click="toShare2('PDF')">
            <img class='button_share' src='@/assets/img/excel_share.png'>
            <span class='button_text'>分享PDF</span>
          </van-button>
        </div>
      </van-tab>
    </van-tabs>
    <van-overlay :show="showShare" @click="show = false" style="padding:56px 0px;text-align:center;">
      <img src="@/assets/img/shareLine.png" style="width: 100px;position: absolute;top: 0px;right: 4%;" />
      <div><img src="@/assets/img/share.png" style="width:90%;left:5%;"/></div>
      <div style="position: absolute;left: 50%;margin-left: -55px;bottom: 240px;">
        <img src="@/assets/img/shareButton.png" style="width:110px;" @click="cancelShare"/>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import wx from 'weixin-js-sdk';
  import { getShare } from '@/server'
  import { comconplanpdf, comcarplanpdf } from '@/api/enterprise'
  import { Toast } from 'vant';
  import Loading from "@/components/Loading";
  import { setCookie, getCookie, nfmoney, domainUrl, hidewxOption } from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      openId: getCookie('openId'),
      carDetailShow: false,
      list:[],
      active: 0,
      showShare: false,
      contractNumber: this.$route.query.contractNumber,
      excel_path1: '',
      pdf_path1: '',
      excel_path2: '',
      pdf_path2:'',
      pdf1_name: '',
      pdf2_name: '',
      isLoading1: true,
      isLoading2: true,
      createDate1: '',
      createDate2: '',
      loginMobile: sessionStorage.getItem('loginMobile') || ''
    };
  },
  mounted(){
    window.addEventListener('message',this.iframeLoaded1)
  },
  created(){
    //隐藏分享
    hidewxOption()

    this.getconplanpdf()
    this.getcarplanpdf()
  },
  methods: {
    iframeLoaded1(event){
      if(event.data.isLoading === 'false'){
        this.isLoading1 = false
      }
    },
    iframeLoaded2(event){
      if(event.data.isLoading === 'false'){
        this.isLoading2 = false
      }
    },
    tabChange(name,title){
      if(name == 1){
        window.addEventListener('message',this.iframeLoaded2)
      }
    },
    getconplanpdf(){
      comconplanpdf({
        contract_number: this.contractNumber
      }).then(res=>{
        if(res.wx_code=='200'){
          this.pdf1_name = res.pdf_name
          this.createDate1 = res.create_date
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    getcarplanpdf(){
      comcarplanpdf({
        contract_number: this.contractNumber
      }).then(res=>{
        if(res.wx_code=='200'){
          this.pdf2_name = res.pdf_name
          this.createDate2 = res.create_date
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    toShare1(type){
      let linkUrl
      if(type == 'Excel'){
        linkUrl = domainUrl + '/enterprise/share?fileName=' + this.pdf1_name.slice( 0, -3 ) + 'xlsx' + '&phone=' + this.loginMobile + '&createDate='+ this.createDate1
      }else{
        linkUrl = domainUrl + '/enterprise/share?fileName=' + this.pdf1_name + '&phone=' + this.loginMobile + '&createDate='+ this.createDate1
      }
      wx.ready( ()=> { 
        //打开微信分享
        wx.showOptionMenu()
        wx.hideMenuItems({
          menuList: ["menuItem:share:timeline","menuItem:share:qq","menuItem:share:weiboApp","menuItem:favorite","menuItem:share:QZone"] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
        });
      //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        wx.updateAppMessageShareData({ //需在用户可能点击分享按钮前就先调用
          title: '合同电子租金计划表', // 分享标题
          desc: this.contractNumber + '\n此链接4小时后失效', // 分享描述
          link: linkUrl,
          imgUrl: 'https://flcs.1jianqidong.com/oraflfile/content/group1/M00/00/18/CgYBGmZsG7mAPnbUAACP9DPGna8429.png', // 分享图标
          success: function () {
          },
          fail: function (data) {
          },
        })
      });
      this.showShare = true;
    },
    toShare2(type){
      let linkUrl
      if(type == 'Excel'){
        linkUrl = domainUrl + '/enterprise/share?fileName=' + this.pdf2_name.slice( 0, -3 ) + 'xlsx' + '&phone=' + this.loginMobile + '&createDate='+ this.createDate2
      }else{
        linkUrl = domainUrl + '/enterprise/share?fileName=' + this.pdf2_name + '&phone=' + this.loginMobile + '&createDate='+ this.createDate2
      }
      wx.ready(()=> { 
        //打开微信分享
        wx.showOptionMenu()
        wx.hideMenuItems({
          menuList: ["menuItem:share:timeline","menuItem:share:qq","menuItem:share:weiboApp","menuItem:favorite","menuItem:share:QZone"] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
        });
      //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        wx.updateAppMessageShareData({ //需在用户可能点击分享按钮前就先调用
          title: '单车电子租金计划表', // 分享标题
          desc: this.contractNumber + '\n此链接4小时后失效', // 分享描述
          link: linkUrl,
          imgUrl: 'https://flcs.1jianqidong.com/oraflfile/content/group1/M00/00/18/CgYBGmZsG7mAPnbUAACP9DPGna8429.png', // 分享图标
          success: function () {
          },
          fail: function (data) {
          },
        })
      });
      this.showShare = true;
    },
    cancelShare(){
      this.showShare = false;
    },
  },
};
</script>
<style scoped>
.wrap{
  background: #F8F8F8;
}
.iframeBox{
  width: 100%;
  /* height: 100%; */
  border: 0;
  flex: 1;
  background: #FFFFFF;
  /* margin-bottom: 1.3rem; */
}
.footer_button{
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button_share{
  width: 13px;
  height: 13px;
  margin-right: 6px;
}
.button_text{
  color: #ffffff;
  font-size: 15px;
}
</style>
<style>
#app{
  background: #F8F8F8 !important;
}
.contractList .van-tabs__wrap{
  padding: 5px 0; 
}
.contractList .van-tabs--card{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.contractList .van-tabs__nav--card{
  border-radius: 20px;
  margin: 0 95px;
}
.contractList .van-tabs__nav--card .van-tab{
  border:none;
}
.contractList .van-tabs__nav--card .van-tab.van-tab--active:first-child{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.contractList .van-tabs__nav--card .van-tab.van-tab--active:last-child{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.contractList .van-tabs__content{
  flex: 1;
}
.contractList .van-tabs__content .van-tab__pane{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer_button .van-button{
  line-height: 16px;
}
</style>